/* LOGIN ************************************************************************************************/

.login {
    padding: 1rem;
}

.login-button {
    text-align: center !important;
}

.login button {
    background-color: var(--lightblue) !important;
    color: white !important;
    border-radius: 0 !important;
    position: relative;
    overflow: hidden;
}

.login button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgb(255, 255, 255));
    transition: left 0.3s ease;
}

.login button:hover::before {
    left: 100%;
    opacity: 100%;
}


.login h2 {
    border-bottom: 1px solid black;
}

.login input {
    padding: 0.5rem !important;
    border-radius: 0 !important;

}