/* NAVIGATION */

#navigation {
    margin-left: auto;
    z-index: 5 !important;
  }
  
  #header-container .item {
    padding: 1rem 1rem !important;
    margin: 0 !important;
    transition: background-color .22s ease, color .22s ease;
    color: #68778D !important;
    border: none !important;
    text-transform: initial;
    font-size: 1rem;
  }
  
  #header-container .item:hover {
    background-color: var(--lightblue) !important;
    color: white !important;
  }
  
  #header-container .item svg {
    height: 15px;
    width: 15px;
    margin-left: 0.5rem;
    margin-right: -0.5rem;
  }
  
  #header-container .nav-item {
    position: relative !important;
  }
  
  .active {
    border: none !important;
  }
  
  .secondnav .item {
    padding: 1rem !important;
  }
  
  .secondnav {
    position: absolute;
    min-width: 180px;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    /* Smooth transition */
    /*transform: translateY(-10px);
    /* Initial position above, will slide down */
    background-color: white;
  }
  
  .topoffset {
    animation: slideUp 0.3s ease;
    z-index: 10 !important;
  }
  
  
  .leftoffset {
    top: 0 !important;
    left: 100%;
    background-color: white;
    animation: slideLeft 0.3s ease;
    z-index: 3 !important;
  }
  
  
  @keyframes slideUp {
    from {
      transform: translateY(18px);
      opacity: 0;
    }
  
    to {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes slideLeft {
    from {
      opacity: 0;
      transform: translateX(18px);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .mobile-menu {
    left: auto !important;
  }
  
  .mobile-menu .ui.pointing.secondary.stackable.menu {
    align-items: initial !important;
  }
  .mobile-menu .ui.pointing.secondary.stackable.menu .item {
    justify-content: left !important;
    padding: 1rem !important;
  }
  
  .mobile-menu .ui.pointing.secondary.stackable.menu .secondnav {
    position: relative !important;
    margin-left: 2rem !important;
  }
  