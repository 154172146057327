.block.form .ui.form .ui.message {
    display: flex;
    width: 80%;
    margin: 3rem auto 0;
}

.block.form .ui.form .row.row-padded-top {
    padding-top: 1rem;
}

.block.form .ui.button.add-element:hover {
    color: white;
}

.block.form .ui.segment {
    padding: 0;
    margin: 0 !important;
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
}

.block.form .column {
    padding: 0 !important;
    width: 100% !important;
}

.block.form .ui.grid {
    margin: 0 !important;
}

.block.form .wrapper {
    border: none !important;
}

.block.form .input {
    align-items: center !important;
}



.block.form textarea {
    height: 150px !important; 
    margin-bottom: 0.5rem !important;
} 

.block.form button {
    border-radius: 0;
}

.block.form .help {
    margin: 0 0 0.5rem 0 !important;
    border: none !important;
    padding: 0 !important;
}