#page-search .searchbox {
    padding: 0 !important;
    margin: 1rem 0!important;
}

#page-search .searchbox button {
    width: 100px !important;
    background-color: var(--blue); 
    text-align: center;
}

#page-search .searchbox svg {
    fill: white !important;
    height: 20px !important; 
}

#page-search h2 {
    text-align: left !important; 
}